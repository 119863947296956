import { ThemeProvider, CssBaseline } from '@mui/material'
import { useMUITheme } from './hooks/useMUITheme/useMUITheme'

export const AppStylesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useMUITheme()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <style global={true} jsx={true}>
        {`
          body {
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizelegibility;
          }
          .pac-container {
            z-index: 10000;
          }
          .MuiAutocomplete-option {
            font-size: 14px;
          }
          .MuiPickersBasePicker-pickerView {
            padding-bottom: 24px;
          }
          #finicityConnectIframe {
            z-index: 10000 !important;
            height: 100vh;
            width: 100vw;
            inset: 0;
            position: fixed;
            background: #000000cc;
          }
        `}
      </style>
      {children}
    </ThemeProvider>
  )
}
