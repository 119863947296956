import { t } from 'lib/translations/i18next'
import { ProviderEnum } from 'gql/types'

export const getAccountingPlatformName = (accountingPlatform: Maybe<ProviderEnum>) => {
  switch (accountingPlatform) {
    case ProviderEnum.QUICK_BOOKS:
      return t('qbo')
    case ProviderEnum.XERO:
      return t('xero')
    case ProviderEnum.NETSUITE:
      return t('netsuite')
    case ProviderEnum.FINALOOP:
      return t('finaloop')
    default:
      return t('unknown')
  }
}
