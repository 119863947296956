import type { ApolloError } from '@apollo/client'
import type { GraphQLFormattedError } from 'graphql'
import { datadogRum } from '@datadog/browser-rum'
import type { IUserTraits } from './types'

type TErrorParams = Record<string, unknown>

export const initDatadog = () => {
  const applicationId = process.env.DATADOG_APPLICATION_ID
  const clientToken = process.env.DATADOG_CLIENT_TOKEN

  if (applicationId && clientToken) {
    datadogRum.init({
      applicationId,
      clientToken,
      env: process.env.VERCEL_ENV || 'development',
      version: process.env.SHORT_SHA,
      site: 'datadoghq.com',
      service: 'settle-web',
      traceSampleRate: 100,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      allowUntrustedEvents: true,
      allowedTracingUrls: [process.env.BACKEND_APP_URL],
      defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
  }
}

export const addAction = (name: string, context?: Record<string, unknown>) => {
  datadogRum.addAction(name, context)
}

export const updateUser = (userId?: string | null, traits: IUserTraits = {}) => {
  datadogRum.setUser({
    id: userId ?? undefined,
    userId,
    ...traits,
  })
}

/**
 * Can be used to log errors on client and server side
 */
export const logError = (err: Error | GraphQLFormattedError, params?: TErrorParams): void => {
  datadogRum.addError(err, params)
}

export const logApolloError = (err: ApolloError, params?: TErrorParams): void => {
  err.graphQLErrors.forEach((error) => logError(error, params))
}
