import { throttle, isEmpty } from 'lodash'
import { datadogRum } from '@datadog/browser-rum'
import { getCookie } from 'cookies-next'
import { AnalyticsBrowser } from '@segment/analytics-next'
import extendWithAmplitudeSessionReplay from '@amplitude/segment-session-replay-wrapper'
import { isImpersonationSession } from 'lib/helpers/isImpersonationSession/isImpersonationSession'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import { segmentServerSide } from './segmentServerSide'
import type { IUserTraits } from './types'

type TProperties = Record<string, unknown>
type TParams = { includingIntercom?: boolean }

const isProduction = process.env.VERCEL_ENV === 'production'

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.SEGMENT_WRITE_KEY ?? '',
})

if (isProduction && process.env.AMPLITUDE_API_KEY) {
  extendWithAmplitudeSessionReplay({
    segmentInstance: analytics,
    amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
    sessionReplayOptions: { logLevel: 1, sampleRate: 1 },
  }).catch(console.error)
}

export const isTrackingDisabled = () => isImpersonationSession() || isSSR()

export const isClientAnalyticsUnavailable = async (): Promise<boolean> => {
  await analytics?.ready?.()

  const isAnalyticsInitialized = analytics?.instance?.initialized ?? false

  const user = await analytics?.user?.()
  const anonymousId = user?.anonymousId()
  const id = user?.id()

  return !isAnalyticsInitialized && !anonymousId && !id
}

const isServerSideFallbackRequired = async () => (isProduction ? await isClientAnalyticsUnavailable() : false)

const includingIntercom = {
  All: true,
  Intercom: true,
}

const exceptIntercom = {
  ...includingIntercom,
  // filtering for Intercom destination inside of Segment is available only on expensive plan,
  // therefore this flag lets Segment know that "track" events should not be sent to Intercom
  Intercom: false,
}

const getFacebookContext = () => {
  const fbc = getCookie('_fbc')?.toString() || null
  const fbp = getCookie('_fbp')?.toString() || null

  return { fbc, fbp }
}

const getExtraContext = () => ({
  facebook: getFacebookContext(),
})

const logToConsoleIfNeeded = (event: string, properties: TProperties = {}) => {
  if (isSSR() || !window.SHOW_EVENTS_IN_CONSOLE) {
    return
  }

  // eslint-disable-next-line no-console
  console.log(event, isEmpty(properties) ? undefined : properties)
}

export const identifyUser = async (userId: string, traits: IUserTraits) => {
  if (isTrackingDisabled()) {
    return
  }

  try {
    await analytics?.identify(
      userId,
      traits,
      { integrations: includingIntercom, context: getExtraContext() },
      () => window.Intercom?.('update'),
    )

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.identify({ userId, traits, integrations: includingIntercom })
      await segmentServerSide.track({
        event: "Couldn't init analytics on the client side",
        integrations: exceptIntercom,
        userId,
      })
    }
  } catch (err) {
    console.error(err)
  }
}

export const trackPage = async (name = 'Page view.', properties: TProperties = {}) => {
  logToConsoleIfNeeded(name, properties)

  if (isTrackingDisabled()) {
    return
  }

  try {
    await analytics?.page(name, properties, { integrations: includingIntercom, context: getExtraContext() })

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.page({ name, properties, integrations: includingIntercom })
    }
  } catch (err) {
    console.error(err)
  }
}

export const logEvent = async (event: string, properties: TProperties = {}, params: TParams = {}) => {
  datadogRum.addAction(event, properties)
  logToConsoleIfNeeded(event, properties)

  if (isTrackingDisabled()) {
    return
  }

  try {
    await analytics?.track(event, properties, {
      integrations: params.includingIntercom ? includingIntercom : exceptIntercom,
      context: getExtraContext(),
    })

    if (await isServerSideFallbackRequired()) {
      await segmentServerSide.track({ event, properties, integrations: exceptIntercom })
    }
  } catch (err) {
    console.error(err)
  }
}

export const resetTracking = () => {
  if (isTrackingDisabled()) {
    return
  }

  analytics?.reset()
  window.Intercom?.('shutdown')
}

export const logEventThrottled = throttle(logEvent, 1000, { leading: false })
