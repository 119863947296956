/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonTertiaryBorderWidthDefault = '0px'
export const ButtonTertiaryBorderWidthHovered = '0px'
export const ButtonTertiaryBorderWidthPressed = '0px'
export const ButtonTertiaryBorderWidthDisabled = '0px'
export const ButtonTertiaryColorPositiveDefaultBackground = '#ffffff00'
export const ButtonTertiaryColorPositiveDefaultOnBackground = '#3E8672'
export const ButtonTertiaryColorPositiveDefaultOutline = '#ffffff00'
export const ButtonTertiaryColorPositiveHoveredBackground = '#00000014'
export const ButtonTertiaryColorPositiveHoveredOnBackground = '#3E8672'
export const ButtonTertiaryColorPositiveHoveredOutline = '#ffffff00'
export const ButtonTertiaryColorPositivePressedBackground = '#3e867229'
export const ButtonTertiaryColorPositivePressedOnBackground = '#3E8672'
export const ButtonTertiaryColorPositivePressedOutline = '#ffffff00'
export const ButtonTertiaryColorPositiveDisabledBackground = '#ffffff00'
export const ButtonTertiaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonTertiaryColorPositiveDisabledOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveDefaultBackground = '#ffffff00'
export const ButtonTertiaryColorDestructiveDefaultOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructiveDefaultOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveHoveredBackground = '#f4511e14'
export const ButtonTertiaryColorDestructiveHoveredOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructiveHoveredOutline = '#ffffff00'
export const ButtonTertiaryColorDestructivePressedBackground = '#f4511e29'
export const ButtonTertiaryColorDestructivePressedOnBackground = '#f4511e'
export const ButtonTertiaryColorDestructivePressedOutline = '#ffffff00'
export const ButtonTertiaryColorDestructiveDisabledBackground = '#ffffff00'
export const ButtonTertiaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonTertiaryColorDestructiveDisabledOutline = '#ffffff00'
export const ButtonTertiaryColorWarningDefaultBackground = '#ffffff00'
export const ButtonTertiaryColorWarningDefaultOnBackground = '#fb8c00'
export const ButtonTertiaryColorWarningDefaultOutline = '#ffffff00'
export const ButtonTertiaryColorWarningHoveredBackground = '#fb8c0014'
export const ButtonTertiaryColorWarningHoveredOnBackground = '#fb8c00'
export const ButtonTertiaryColorWarningHoveredOutline = '#ffffff00'
export const ButtonTertiaryColorWarningPressedBackground = '#fb8c0029'
export const ButtonTertiaryColorWarningPressedOnBackground = '#fb8c00'
export const ButtonTertiaryColorWarningPressedOutline = '#ffffff00'
export const ButtonTertiaryColorWarningDisabledBackground = '#ffffff00'
export const ButtonTertiaryColorWarningDisabledOnBackground = '#00000061'
export const ButtonTertiaryColorWarningDisabledOutline = '#ffffff00'
