/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const TableRowColorDefaultBackground = '#ffffff'
export const TableRowColorDefaultText = '#000000de'
export const TableRowColorHoveredBackground = '#00000014'
export const TableRowColorHoveredText = '#000000de'
export const TableRowColorSelectedBackground = '#3e867229'
export const TableRowColorSelectedText = '#000000de'
export const TableRowColorDisabledBackground = '#ffffff'
export const TableRowColorDisabledText = '#000000de'
