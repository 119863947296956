import noop from 'lodash/noop'
import { createContext, useState, useMemo, type FC, type PropsWithChildren } from 'react'
import { useIsomorphicLayoutEffect } from '@react-hookz/web'

interface IBusinessIdContext {
  businessId: string | null
  setBusinessId: (businessId: string) => void
}

export const BusinessIdContext = createContext<IBusinessIdContext>({
  businessId: null,
  setBusinessId: noop,
})

interface IBusinessIdProviderProps {
  businessId: IBusinessIdContext['businessId']
}

export const BusinessIdProvider: FC<PropsWithChildren<IBusinessIdProviderProps>> = ({ children, ...props }) => {
  const [businessId, setBusinessId] = useState(props.businessId)

  useIsomorphicLayoutEffect(() => {
    setBusinessId(props.businessId)
  }, [props.businessId])

  const value = useMemo(() => ({ businessId, setBusinessId }), [businessId, setBusinessId])

  return <BusinessIdContext.Provider value={value}>{children}</BusinessIdContext.Provider>
}
