import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'
import { BusinessHookDocument } from './useBusiness.graphql'

interface IUseBusinessArgs {
  fetchPolicy?: WatchQueryFetchPolicy
}

export const useBusiness = ({ fetchPolicy }: IUseBusinessArgs = {}) => {
  const businessId = useBusinessIdPathParam()
  const { data, previousData, loading } = useQuery(BusinessHookDocument, {
    fetchPolicy,
    variables: { businessId },
    skip: !businessId,
  })

  return {
    loading,
    business: data?.businesses?.[0] || previousData?.businesses?.[0],
  }
}
