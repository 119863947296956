// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import translation from 'public/locales/en.json'

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en'],
    resources: { en: { translation } },
    keySeparator: '::',
    nsSeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['b', 'br', 'i', 'li', 'ul', 'ol', 'span', 's'],
    },
    compatibilityJSON: 'v4',
  })

export const i18next = i18n
export const t = i18n.t
