import {
  type GridColDef,
  GridColumnMenu,
  type GridColumnMenuProps,
  GridColumnMenuHideItem,
  type IColumnMenuItemsConfig,
} from '@mui/x-data-grid-premium'
import { FilterDrawerItem } from './components/FilterDrawerItem/FilterDrawerItem'

interface IColumnMenuProps extends GridColumnMenuProps {
  itemsConfig: IColumnMenuItemsConfig
  onFilterClick: (colDef: GridColDef) => void
}

export const ColumnMenu: React.FC<IColumnMenuProps> = ({ itemsConfig, onFilterClick, ...props }) => {
  const { filter, hide } = itemsConfig
  const showFilterItem = filter && (props.colDef.filterable === undefined || props.colDef.filterable)
  const showHideItem = hide && (props.colDef.hideable === undefined || props.colDef.hideable)

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuPinningItem: null,
        columnMenuAggregationItem: null,
        columnMenuGroupingItem: null,
        columnMenuColumnsItem: showHideItem ? GridColumnMenuHideItem : null,
        filterDrawerItem: showFilterItem ? FilterDrawerItem : null,
      }}
      slotProps={{
        filterDrawerItem: {
          onFilterClick,
        },
      }}
    />
  )
}
