import { styled } from '@mui/material/styles'

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndexes.snackbar,
  left: '50%',
  top: theme.spacing(2),
  transform: 'translateX(-50%)',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: 'center',
  maxWidth: '600px',
}))
